import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import "./App.css";
import Web3 from "web3";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import abi from "./abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xdE66e7d6ecb10E1713D29ef69Cbb786de64C7380"
    );
    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.tokenCount().call();
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }

    // stars();
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");

    // ❌ Check if Meta Mask Extension exists
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/honeyclub.io/");
    }
  }

  // Create a provider to interact with a smart contract
  async function connectWallet() {
    if (typeof window.ethereum !== "undefined") {
      await requestAccount();

      const provider = new ethers.providers.Web3Provider(window.ethereum);
    }
  }

  const mint = async () => {
    if (add) {
      if (instance) {
        console.log(
          await instance.methods.mint(mintNumber).send({
            from: add,
            value: (
              (await instance.methods.cost().call()) * mintNumber
            ).toString(),
          })
        );
      } else {
        console.log("error");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 11) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="app">
        <div className="scene">
          <Container className="mt-3">
            <Navbar
              className="custom-navbar"
              variant="dark"
              bg="light"
              expand="lg"
            >
              <div className="">
                <img
                  onClick={plus}
                  className="logo img-fluid"
                  src={require("./assets/img/logo.png").default}
                  alt=""
                />
              </div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <div className=" text-center rmt">
                    <div onClick={requestAccount} className="btn cusbtn">
                      <span className="font-weight-bold text-uppercase">
                        {add ? add.substring(0, 15) : "Connect Wallet"}
                      </span>
                    </div>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>

          <img
            className="cloud3"
            src={require("./assets/img/cloud3.png").default}
            alt=""
          />
          <img
            className="cloud4"
            src={require("./assets/img/cloud1.png").default}
            alt=""
          />

          <Container className="showcase">
            <div className="">
              <Container className="d-flex justify-content-center">
                <div className="mint">
                  <div className="">
                    <h1 className="mih1 text-center  text-uppercase mb-3">
                      Mint Your NFT's
                    </h1>
                    <p className="subtitle p-0 m-0 text-center  text-uppercase">
                      1 honey club nft costs 0.01 eth.
                    </p>
                    <p className="subtitle p-0 m-0 text-center  text-uppercase">
                      First 1500 are free!
                    </p>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <div className="mx-3">
                        <img
                          onClick={minus}
                          className="plus-img"
                          src={require("./assets/img/4.png").default}
                          alt=""
                        />
                      </div>
                      <div className="display-number d-flex justify-content-center align-items-center">
                        <span className="number">{mintNumber}</span>
                      </div>
                      <div className="mx-3">
                        <img
                          onClick={plus}
                          className="plus-img"
                          src={require("./assets/img/2.png").default}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <button className="btn cusbtn  text-uppercase">
                        Sold Out
                      </button>
                    </div>
                    {/* <div className="text-center mt-4">
                      <h3 className="font-weight-bold bfont">
                        {" "}
                        {totalMinted} / 2222
                      </h3>
                    </div> */}
                    <div className="">
                      <div className="text-center mt-4">
                        <a
                          href="https://Twitter.com/HoneyClubNFT"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Twitter.png").default}
                            alt=""
                          />
                        </a>
                        <a
                          href="https://discord.gg/FrCHTn3JRF"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Discord.png").default}
                            alt=""
                          />
                        </a>
                        <a
                          href="https://opensea.io/collection/honeyclubnft"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            style={{ borderRadius: "10px" }}
                            src={require("./assets/img/Opensea.png").default}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </Container>
        </div>

        <div className="roadmap " id="roadmap">
          <h2
            data-aos="fade-up"
            data-aos-duration="2000"
            className="text-center mih1 text-uppercase font-weight-bold"
          >
            roadmap
          </h2>
          <Container className="mt-4">
            <Row>
              <Col>
                <div className="">
                  <div className="">
                    <div className="">
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        style={{ fontSize: "1.6rem" }}
                        className="mt-3 "
                      >
                        Honey Club NFT is a collection of 2,222 sweet honey
                        bears. Each honey bear resides on the Ethereum
                        blockchain as a unique, non-fungible token (NFT) made up
                        out of a wide combination of traits, colors and sizes.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h4
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="fcursive"
                      >
                        IMMEDIATE REWARDS:
                      </h4>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        style={{ fontSize: "1.6rem" }}
                        className="mt-3 "
                      >
                        Each honey bear will be airdropped a Decentraland
                        wearable NFT 1 week after mint. There will be many
                        rewards for holding your Honey Club NFT. We plan to
                        purchase digital land, donate to charity (of the holders
                        choice) and host many fun events as we build our
                        community. This is just the first step, a more detailed
                        roadmap will be announced shortly.
                      </p>
                    </div>

                    <div className="mt-5">
                      <h4
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="fcursive"
                      >
                        UTILITY:
                      </h4>
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        style={{ fontSize: "1.6rem" }}
                        className="mt-3 "
                      >
                        Stay tuned 😉 ﻿
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div id="faq" className="faq">
          <div className="">
            <div className="text-center">
              <h1
                data-aos="fade-up"
                data-aos-duration="2000"
                className="mih1 text-uppercase"
              >
                Faq
              </h1>
            </div>
            <Container className="mt-5 ">
              <Row>
                <Col lg="12" className="">
                  <Accordion>
                    <AccordionItem
                      style={{ backgroundColor: "#FECA69" }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">Mint information?</h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div>
                          <span className="">
                            The mint sale will be on the 19th of March. There
                            are a total of 2,222 NFTs allocated for public mint.
                          </span>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      style={{ backgroundColor: "tomato" }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            What will be the mint price?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          The first 1500 Honey Club NFTs will be Free! The
                          remaining 722 are 0.01 ETH each.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      style={{ backgroundColor: "skyblue" }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            How do I buy a Honey Club NFT?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          During any of the sales you can head to the mint page
                          and get your mint. Otherwise, you can purchase one on
                          OpenSea.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      style={{ backgroundColor: "#be7bdb " }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">Roadmap?</h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          We have plenty in store for the Honey Club holders!
                          More will be announced soon.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      style={{ backgroundColor: "lightgreen " }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">Further questions?</h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          Feel free to ask any questions to the community or
                          administrators in Discord.
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem
                      style={{ backgroundColor: "orange " }}
                      className="bt pt-2 my-3 text-left"
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <AccordionItemHeading>
                        <AccordionItemButton className="">
                          <h2 className="subtitle">
                            Where can I view my NFTs ?
                          </h2>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <div className="">
                          <span>
                            Once minted, simply connect to your OpenSea account
                            to view your NFTs.
                          </span>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </div>
        </div>

        <div className="team">
          <Container>
            <h1
              data-aos="fade-up"
              data-aos-duration="2000"
              className="text-center mih1"
            >
              THE WONDERFUL TEAM
            </h1>
            <Row className="mt-5">
              <Col lg="4">
                <div className="">
                  <div className="">
                    <img
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      className="img-fluid team-img"
                      src={require("./assets/img/boben.png").default}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <div className="">
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="font-weight-bold"
                      >
                        Papa Bear
                      </p>
                      <p data-aos="fade-up" data-aos-duration="2000">
                        Artist &amp; Creator
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="4" className="rmt-2">
                <div className="">
                  <div className="">
                    <img
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      className="img-fluid team-img"
                      src={require("./assets/img/token.png").default}
                      alt=""
                    />
                  </div>
                  <div className="mt-3 text-center">
                    <div className="">
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="font-weight-bold "
                      >
                        Big Bear
                      </p>
                      <p data-aos="fade-up" data-aos-duration="2000">
                        Developer
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg="4" className="rmt-2">
                <div className="">
                  <div className="">
                    <img
                      data-aos="fade-up"
                      data-aos-duration="2000"
                      className="img-fluid team-img"
                      src={require("./assets/img/mm.png").default}
                      alt=""
                    />
                  </div>
                  <div className="mt-3  text-center">
                    <div className="">
                      <p
                        data-aos="fade-up"
                        data-aos-duration="2000"
                        className="font-weight-bold fcursive"
                      >
                        Hannah
                      </p>
                      <p data-aos="fade-up" data-aos-duration="2000">
                        Marketing
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="footer">
        <Container>
          <div className="d-flex justify-content-between align-items-center">
            <div className="">
              <img
                onClick={plus}
                className="logo img-fluid"
                src={require("./assets/img/logo.png").default}
                alt=""
              />
            </div>
            <div className="text-center d-flex align-items-center">
              <>
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Honey Club | Original. All rights
                  reserved.
                </span>
              </>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default App;
